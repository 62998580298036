


































































































































































































































import Vue from "vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import { userServices } from "@/services/user.services";
import {
  ResponseListOfMenus,
  CreateMenuPrivilege,
  ResponseListOfPrivilege,
} from "@/models/interface/user.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  data() {
    return {
      authPrivilege: [] as string[],
      loadingTable: false as boolean,
      switchActive: true as boolean,
      dataDetail: "" as string,
      dataListMenu: {} as ResponseListOfMenus,
      dataListPrivilege: {} as ResponseListOfPrivilege,
      typemodal: "" as string,
      isFormSubmitted: false as boolean,
      selectedRowKeysModal: [] as number[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      sort: "" as string,
      trigerdisable: false as boolean,
      titlemodalOpen: "" as string,
      direction: "" as string,
      modalOpen: false as boolean,
      form: this.$form.createForm(this, { name: "addMenuForm" }),
      tempPrivilegeid: [] as string[],
      columnsTable: [
        {
          title: this.$t("lbl_name"),
          dataIndex: "name",
          key: "name",
          sorter: true,
          width: 200,
          sortDirections: ["ascend", "descend", null],
          scopedSlots: { customRender: "isClick" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: true,
          width: 200,
          scopedSlots: { customRender: "isNull" },
          sortDirections: ["ascend", "descend", null],
        },
        {
          title: this.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          width: 100,
          scopedSlots: { customRender: "isEnable" },
        },
      ] as any,
      columnsTableModal: [
        {
          title: this.$t("lbl_privilege"),
          dataIndex: "privilege",
          key: "menu",
          width: 220,
          scopedSlots: { customRender: "menu" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: false,
        },
      ],
      dataSourceModal: [] as CreateMenuPrivilege[],
      formRules: {
        name: {
          label: this.$t("lbl_name"),
          name: "name",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        description: {
          label: this.$t("lbl_description_placeholder"),
          name: "description",
          placeholder: this.$t("lbl_type_here"),
          decorator: ["description", {}],
        },
        active: {
          label: this.$t("lbl_active"),
          name: "active",
          decorator: ["active"],
        },
      },
    };
  },
  mounted() {
    this.getListOfMenu("");
  },
  created() {
    const auth = getauthorities();
    auth.forEach((dataAuth) => {
      if (dataAuth?.name === "menu") {
        this.authPrivilege = dataAuth.value;
        this.columnsTable.push({
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: dataAuth.actions,
          width: 120,
          align: "center",
          fixed: "right",
        });
      }
    });
  },
  methods: {
    showConfirmation() {
      if (this.selectedRowKeysModal.length > 0) {
        this.$confirm({
          title: this.$t("lbl_modal_delete_title_confirm"),
          content: this.$t("lbl_modal_delete_info", {
            count: this.selectedRowKeysModal.length,
          }),
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: this.$t("lbl_error_title") as string,
          description: this.$t("lbl_modal_delete_error_description") as string,
        });
      }
    },
    handleCLickFLoating() {
      this.createNew();
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfMenu("");
    },
    reponseEditTable(response): void {
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_menu").toString();
      this.dataDetail = "/" + response.data.id;
      this.resetCheckbox();
      this.ResetFilter(false);
      this.getListPrivilege(true);
      this.getListOfMenu("/" + response.data.id);
    },
    editModal(): void {
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_menu").toString();
      this.dataSourceModal.forEach((item) => {
        item.disabled = false;
      });
      this.getListPrivilege(true);
      this.resetCheckbox();
    },
    getListPrivilege(modal): void {
      let params = {
        limit: 1000,
        page: 0,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sort = this.sort;
      userServices
        .listOfPrivilege(params)
        .then((res: ResponseListOfPrivilege) => {
          res.data.forEach(
            (dataObject, index) => (
              (dataObject.key = index), (dataObject.name = dataObject.authority)
            )
          );
          this.dataListPrivilege = res;
          this.columnsTableModal[0]["responsiveColSelect"] = [
            {
              name: "menu",
              style: "width:100%;",
              placeholder: this.$t("lbl_list_role_title"),
              options: res.data,
              value: "id",
              disabled: "",
            },
          ];
          this.modalOpen = modal;
        });
    },
    handleSelectTableModal(value, key, col, recordOptions): void {
      this.dataSourceModal[key] = {
        ...this.dataSourceModal[key],
        privilege: value,
        id: value,
        description: "",
      };
      const option = recordOptions.find((data) => data.id === value);
      this.dataSourceModal[key] = {
        ...this.dataSourceModal[key],
        description: option?.description ? option?.description : "-",
      };
      this.dataSourceModal = this.dataSourceModal.slice();
      this.dataSourceModal[key].privilege = value;
      this.dataSourceModal[key].id = value;
      this.dataSourceModal[key].description = option.description;
    },
    resetCheckbox(): void {
      this.selectedRowKeysModal = [];
    },
    onSelectChangeModal(selectedRowKeysModal) {
      this.selectedRowKeysModal = selectedRowKeysModal;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfMenu("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfMenu("");
    },
    reponseSearchInput(response): void {
      if (response)
        this.search =
          "name~*" + response + "*_OR_description~*" + response + "*";
      else this.search = "";
      this.getListOfMenu("");
    },
    createNew(): void {
      this.resetCheckbox();
      this.form.resetFields();
      this.dataSourceModal = [];
      this.trigerdisable = false;
      this.typemodal = "create";
      this.titlemodalOpen = this.$t("lbl_create_new_menu").toString();
      this.ResetFilter(false);
      this.getListPrivilege(true);
    },

    handleCancel(): void {
      this.modalOpen = false;
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.ResetFilter(false);
      }
      this.getListOfMenu("");
    },
    getListOfMenu(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      userServices
        .listOfMenus(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.map((dataObject, index) => (dataObject.key = index));
            this.dataListMenu = res;
          } else {
            setTimeout(() => {
              this.form.setFieldsValue({
                name: res.name,
                description: res.description ? res.description : "-",
              });
            }, 200);
            this.switchActive = res.active;
            res.privileges.map(
              (dataObject, index) => (
                (dataObject.key = index),
                (dataObject.name = dataObject.authority),
                (dataObject.privilege = dataObject.authority),
                (dataObject.disabled = false)
              )
            );
            this.dataSourceModal = res.privileges;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingTable = false));
    },
    handleAddRow() {
      const dataColumn: CreateMenuPrivilege = {
        privilege: null,
        description: null,
        key: this.dataSourceModal.length,
        id: null,
        disabled: false,
      };
      this.dataSourceModal = [...this.dataSourceModal, dataColumn];
    },
    handleDeleteRow() {
      this.dataSourceModal = this.dataSourceModal.filter((data) => {
        return !this.selectedRowKeysModal.includes(data.key);
      });
      this.dataSourceModal.map((data, index) => (data.key = index));
      this.selectedRowKeysModal = [];
    },
    createNewMenu(datapost): void {
      userServices
        .createMenu(datapost)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfMenu("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_create_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    updateMenu(datapost): void {
      userServices
        .updateMenu(datapost, this.dataDetail)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfMenu("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_update_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(e, type: "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.tempPrivilegeid = [];
          this.dataSourceModal.forEach((item) => {
            if (item.id) {
              this.tempPrivilegeid.push(item.id);
            }
          });
          const checkduplicatedata = this.tempPrivilegeid.some(
            (element, index) => {
              return this.tempPrivilegeid.indexOf(element) !== index;
            }
          );
          if (!checkduplicatedata) {
            const datapost = {
              name: values.name,
              description: values.description,
              privilegeIds: this.tempPrivilegeid,
              active: this.switchActive,
            };
            this.isFormSubmitted = true;
            if (type === "create") {
              this.createNewMenu(datapost);
            } else {
              this.updateMenu(datapost);
            }
          } else {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: this.$t("notif_error_duplicate_data").toString(),
            });
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
  },
});
